.max-h-12 {
  max-height: 3.5rem !important;
}


@media (min-width: 1024px) {
  .lg\:pb-50 {
      padding-bottom: 11rem !important;
  }
}
@media (max-width: 767px) {
.w-2\/3 {
    width: 100% !important;
}
.-right-4 {
    right: 0 !important;
}
.pr-11 {
    padding-right: 1.75rem !important;
}

.pl-20 {
    padding-left: 1.75rem !important;
    padding-top: 50px;
}
.border-mb-0{
  border: 0!important;
}
.p-mb-10{
padding: 10px !important;
}
.position-mb-relative {
    position: relative !important;
}
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
